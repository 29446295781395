
// import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import Layout from './Layout';

// const LogoutPage = () => {
//   const navigate = useNavigate();

//   useEffect(() => {
   
//     localStorage.removeItem('registeredEmail');
//     localStorage.removeItem('registeredMobile');
//     localStorage.removeItem('registeredPassword');
//     localStorage.removeItem('registeredName');

    
//     navigate('/');
//   }, [navigate]);

//   return (
//     <Layout>
//       <div className="mt-5 text-center">
//         <h2>Logging out...</h2>
//       </div>
//     </Layout>
//   );
// };

// export default LogoutPage;


import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear the localStorage
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userID');
    localStorage.removeItem('password');

    // Redirect to the login page after logout
    navigate('/');
  }, [navigate]);

  return null; // No UI needed
};

export default LogoutPage;
