import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import Layout from './Layout';
import '../css/HomePage.css';
// import '../css/PortfolioPage.css';

const RecommendedPortfolio = () => {
  // Hardcoded portfolio data (can be replaced with API data in the future)
  const [portfolioData, setPortfolioData] = useState([
    {
      scheme: 'HDFC Capital Builder Value Fund - Regular Plan - Growth',
      rationale: 'Growth & Value',
    },
    {
      scheme: 'Quant Small Cap Fund - Regular Growth Plan-Growth',
      rationale: 'High Yield - Aggressive',
    },
    {
      scheme: 'ICICI Prudential India Opportunities Fund Growth',
      rationale: 'Growth & Value',
    },
    {
        scheme:'Aditya Birla Sun Life PSU Equity Fund Regular-Growth',
        rationale :'PSU-Growth & Value',
    }
  ]);

  return (
    <Layout>
      <div className="border portfolio-table-container">
        <h3 className="text-center m-4">Recommended Portfolio</h3>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Scheme</th>
              <th>Rationale</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {portfolioData.map((portfolio, index) => (
              <tr key={index}>
                <td>{portfolio.scheme}</td>
                <td>{portfolio.rationale}</td>
                <td>
                  <Button variant="primary" className="me-2">Buy</Button>
                  <Button variant="success">SIP</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Layout>
  );
};

export default RecommendedPortfolio;

// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Table, Button, Spinner, Alert } from 'react-bootstrap';
// import Layout from './Layout';
// import '../css/HomePage.css';
// // import '../css/PortfolioPage.css';

// const RecommendedPortfolio = () => {
//   const [portfolioData, setPortfolioData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get('http://localhost/autowealth.in/api/getRecomodationData.php', { withCredentials: true });
//         console.log(response.data); // Check the structure of the response
        
//         if (response.data.Success) {
//           setPortfolioData(response.data.Data); // Ensure correct key
//         } else {
//           setError(response.data.Message);
//         }
//       } catch (error) {
//         console.error('Error fetching portfolio data:', error);
//         setError('An error occurred while fetching the data.');
//       } finally {
//         setLoading(false);
//       }
//     };
  
//     fetchData();
//   }, []);
  

//   if (loading) {
//     return <Spinner animation="border" />;
//   }

//   if (error) {
//     return <Alert variant="danger">{error}</Alert>; // Display error message if any
//   }

//   return (
//     <Layout>
//       <div className="border portfolio-table-container">
//         <h3 className="text-center m-4">Recommended Portfolio</h3>
//         <Table striped bordered hover responsive>
//           <thead>
//             <tr>
//               <th>Scheme</th>
//               <th>Rationale</th>
//               <th>Options</th>
//             </tr>
//           </thead>
//           <tbody>
//   {portfolioData.map((portfolio, index) => (
//     <tr key={index}>
//       <td>{portfolio.SchemeName}</td> {/* Correctly referencing SchemeName */}
//       <td>{portfolio.rational}</td>    {/* Correctly referencing rational */}
//       <td>
//         <Button variant="primary" className="me-2">Buy</Button>
//         <Button variant="success">SIP</Button>
//       </td>
//     </tr>
//   ))}
// </tbody>

//         </Table>
//       </div>
//     </Layout>
//   );
// };

// export default RecommendedPortfolio;
