import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import Layout from './Layout';
import '../css/HomePage.css';

const InvestNow = () => {
  // State to manage the selected investment type, fund house, fund category, scheme name, and amount
  const [investmentType, setInvestmentType] = useState('');
  const [fundHouse, setFundHouse] = useState('');
  const [fundCategory, setFundCategory] = useState('');
  const [schemeName, setSchemeName] = useState('');
  const [amount, setAmount] = useState('');
  const [submittedData, setSubmittedData] = useState([]);

  // Investment options
  const investmentOptions = [
    'Equity', 'Gold', 'FoF', 'Debt', 'Hybrid', 'Arbitrage', 'Tax Saving', 'ETF'
  ];

  // Dummy data for Fund Houses
  const fundHouses = [
    'Axis Mutual Fund', 'Aditya Birla Fund', 'HDFC', 'Fund House D'
  ];

  // Dummy data for Fund Categories
  const fundCategories = [
    'Small Cap', 'Mid Cap', 'Multi Cap', 'Large Cap', 'Focused', 'Other Equities'
  ];

  // Dummy data for Scheme Names
  const schemeNames = [
    'Scheme 1', 'Scheme 2', 'Scheme 3', 'Scheme 4'
  ];

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Add the selected data along with the entered amount to the submittedData state
    const newData = {
      investmentType,
      fundHouse,
      fundCategory,
      schemeName,
      amount: '' // Initially leave amount blank; user will enter later
    };

    setSubmittedData([...submittedData, newData]);

    // Reset the form fields after submission
    setInvestmentType('');
    setFundHouse('');
    setFundCategory('');
    setSchemeName('');
  };

  return (
    <Layout>
      <Container className="border mt-5">
        <h3 className="text-center m-4">{`Invest Now - ${investmentType}`}</h3>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={4}>
              <Form.Group controlId="investmentType">
                <Form.Label className="bold-label">Type:</Form.Label>
                <Form.Control
                  as="select"
                  value={investmentType}
                  onChange={(e) => setInvestmentType(e.target.value)}
                  required
                >
                  <option value="">Select an investment type</option>
                  {investmentOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row className='mt-4'>
            <Col md={4}>
              <Form.Group controlId="fundHouse">
                <Form.Label className="bold-label">Fund House:</Form.Label>
                <Form.Control
                  as="select"
                  value={fundHouse}
                  onChange={(e) => setFundHouse(e.target.value)}
                  required
                >
                  <option value="">Select Fund House Name</option>
                  {fundHouses.map((house, index) => (
                    <option key={index} value={house}>
                      {house}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group controlId="fundCategory">
                <Form.Label className="bold-label">Fund Category:</Form.Label>
                <Form.Control
                  as="select"
                  value={fundCategory}
                  onChange={(e) => setFundCategory(e.target.value)}
                  required
                >
                  <option value="">Select Fund Category Name</option>
                  {fundCategories.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group controlId="schemeName">
                <Form.Label className="bold-label">Scheme Name:</Form.Label>
                <Form.Control
                  as="select"
                  value={schemeName}
                  onChange={(e) => setSchemeName(e.target.value)}
                  required
                >
                  <option value="">Select Scheme Name</option>
                  {schemeNames.map((scheme, index) => (
                    <option key={index} value={scheme}>
                      {scheme}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <div className="text-center m-3">
            {/* <Button variant="primary" type="submit">
              Proceed
            </Button> */}

            
<div className="d-flex justify-content-center">
  <Button id="btn" type="submit" className="custom-aqua-button">
  Proceed
  </Button>
</div>
          </div>
        </Form>

        {/* Display the submitted data in a table */}
        {submittedData.length > 0 && (
          <div className="mt-5">
            <h3>Investment Details</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Investment Type</th>
                  <th>Fund House</th>
                  <th>Fund Category</th>
                  <th>Scheme Name</th>
                  {submittedData.length > 0 && <th>Enter Amount</th>} {/* Conditional column */}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {submittedData.map((data, index) => (
                  <tr key={index}>
                    <td>{data.investmentType}</td>
                    <td>{data.fundHouse}</td>
                    <td>{data.fundCategory}</td>
                    <td>{data.schemeName}</td>
                    {submittedData.length > 0 && ( /* Render input only after Proceed */
                      <td>
                        <Form.Control
                          type="number"
                          value={data.amount}
                          onChange={(e) => {
                            const newSubmittedData = [...submittedData];
                            newSubmittedData[index].amount = e.target.value;
                            setSubmittedData(newSubmittedData);
                          }}
                        />
                      </td>
                    )}
                    <td>
                      <Button variant="primary" className="m-2">
                        Buy
                      </Button>
                      <Button variant="success" className="m-2">SIP</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </Container>
    </Layout>
  );
};

export default InvestNow;
