import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; 
import axios from 'axios'; 
import Layout from './Layout';
import '../css/HomePage.css';

const SignUpPage = () => {
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate(); 

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const isValidMobile = (mobile) => {
    return /^[0-9]{10}$/.test(mobile);
  };

  const isValidPassword = (password) => {
    return password.length >= 8 && password.length <= 20;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!mobile) {
      setError('Phone number is required.');
      setSuccess('');
      setShowAlert(true);
      return;
    }
    if (!isValidMobile(mobile)) {
      setError('Phone number must be exactly 10 digits.');
      setSuccess('');
      setShowAlert(true);
      return;
    }
    if (!email) {
      setError('Email is required.');
      setSuccess('');
      setShowAlert(true);
      return;
    }
    if (!isValidEmail(email)) {
      setError('Email is invalid.');
      setSuccess('');
      setShowAlert(true);
      return;
    }
    if (!password || !rePassword) {
      setError('Password and confirmation are required.');
      setSuccess('');
      setShowAlert(true);
      return;
    }
    if (!isValidPassword(password)) {
      setError('Password must be between 8 and 20 characters long.');
      setSuccess('');
      setShowAlert(true);
      return;
    }
    if (password !== rePassword) {
      setError('Passwords do not match.');
      setSuccess('');
      setShowAlert(true);
      return;
    }

    const formData = {
      MobileNo: mobile,
      EmailAddress: email,
      UserPassword: password,
      ReferralCode: referralCode || '',
    };

    try {
      const response = await axios.post(
        'http://localhost/autowealth.in/api/user_login/signup.php',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.Success) {
        setSuccess('Signup successful! Redirecting to login...');
        setError('');
        setShowAlert(true);

        setMobile('');
        setEmail('');
        setPassword('');
        setRePassword('');
        setReferralCode('');

        setTimeout(() => {
          setShowAlert(false);
          navigate('/login');
        }, 3000);
      } else {
        setError(response.data.Message);
        setSuccess('');
        setShowAlert(true);
      }
    } catch (error) {
      console.error('Signup failed:', error.message);
      setError('An error occurred. Please try again.');
      setSuccess('');
      setShowAlert(true);
    }
  };

  return (
    <Layout>
      <Container className="mt-5">
        <h3 className="text-center mb-4">Sign Up</h3>
        {showAlert && (
          <Alert variant={error ? 'danger' : 'success'} onClose={() => setShowAlert(false)} dismissible>
            {error || success}
          </Alert>
        )}

        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="formMobile">
                <Form.Label>
                  Enter Mobile No<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter mobile number"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12} md={6}>
              <Form.Group controlId="formEmail">
                <Form.Label>
                  Enter Email Address<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12} md={6}>
              <Form.Group controlId="formPassword">
                <Form.Label>
                  Enter Password<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="formRePassword">
                <Form.Label>
                  Re-enter Password<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Re-enter password"
                  value={rePassword}
                  onChange={(e) => setRePassword(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12} md={6}>
              <Form.Group controlId="formReferralCode">
                <Form.Label>Enter Referral Code (optional)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter referral code"
                  value={referralCode}
                  onChange={(e) => setReferralCode(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12} className="d-flex justify-content-center">
              <Button id="btn" type="submit" className="custom-aqua-button">
                Register
              </Button>
            </Col>
          </Row>
        </Form>
        {/* <p className="mt-3 text-center">
          Already registered? <a href="/login">Login here</a>
        </p> */}

<p className="mt-3 text-center">
  Already registered?{' '}
  <a href="/login" className="custom-login-link">Login here</a>
</p>

      </Container>
    </Layout>
  );
};

export default SignUpPage;
