import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Spinner, Alert } from 'react-bootstrap';
import axios from 'axios';
import Layout from './Layout';
import '../css/PortfolioPage.css';
import { Link } from 'react-router-dom'; // Import Link for navigation

const PortfolioPage = () => {
  const [folioData, setFolioData] = useState(null); 
  const [totalInvestment, setTotalInvestment] = useState(null);
  const [totalCurrentAmount, setTotalCurrentAmount] = useState(null);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost/autowealth.in/api/Portfolio/getFolioData.php', { withCredentials: true });
        if (response.data.Success) {
          setFolioData(response.data.statusData); 
          setTotalInvestment(response.data.totalInvAmount);
          setTotalCurrentAmount(response.data.totalCurAmount);
        } else {
          setError(response.data.Message);
        }
      } catch (error) {
        setError('An error occurred while fetching the data.');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const pnl = totalCurrentAmount - totalInvestment;
  const pnlPercentage = ((pnl * 100) / totalInvestment) || 0;

  const pnlStyle = pnl >= 0 ? { color: 'green' } : { color: 'red' };

  return (
    <Layout>
      <Container className="mt-5">
        <h3>Total Investment:</h3>
        {loading && (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}

        {error && (
          <Alert variant="danger" className="text-center">
            {error}
          </Alert>
        )}

        {!loading && !error && folioData && (
          <>
            <Row className="mb-4">
  <Col md={6}>
    <Card className="border p-3 d-flex flex-column align-items-center">
      <h6>P&L</h6>
      <h6 style={pnlStyle}>₹ {pnl.toFixed(2)}</h6>
      <h6 style={pnlStyle}>{pnlPercentage.toFixed(2)}%</h6>
    </Card>
  </Col>
  <Col md={6}>
    <Card className="border p-3 d-flex flex-column align-items-center">
      <h6>XIRR</h6>
      <div className="d-flex justify-content-center mt-2">
        <Button id="btn" className="custom-aqua-button">
          View Portfolio XIRR
        </Button>
      </div>
    </Card>
  </Col>
</Row>


            <Row className="mb-4">
  <Col md={6}>
    <Card className="border p-3 d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
      <div className="text-center">
        <h6>Invested</h6>
        <h6>₹ {Number(totalInvestment || 0).toFixed(2)}</h6>
      </div>
    </Card>
  </Col>
  <Col md={6}>
    <Card className="border p-3 d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
      <div className="text-center">
        <h6>Current</h6>
        <h6>₹ {Number(totalCurrentAmount || 0).toFixed(2)}</h6>
      </div>
    </Card>
  </Col>
</Row>

          </>
        )}
      </Container>

  <Container>
  <h3>Folio Data:</h3>
  {folioData && folioData.length > 0 ? (
    <Row>
      {folioData.map((item, index) => (
        <Col key={index} sm={12} md={12} lg={12} className="mb-4"> {/* Adjusted grid columns */}
          <Card className='border'>
            <Card.Body>
              {/* Wrap SchemeName with Link, remove underline */}
              <Card.Title>
                <h5>
                  <Link
                    to={`/scheme/${item.schemeId}`}
                    state={{ item }}
                    style={{ textDecoration: 'none', color: 'inherit' }} // No underline and inherit text color
                  >
                    {item.SchemeName}
                  </Link>
                </h5>
              </Card.Title>
              <Card.Subtitle className="mb-3 text-muted">
                <h6>{item.FundType}</h6>
              </Card.Subtitle>

              {/* P&L, Invested, Current all in single row */}
              <Row className="text-center"> {/* Centering text */}
                <Col>
                  <h6 className='text-muted'><strong>P&L</strong></h6>
                  <h6 style={{ color: item.PL >= 0 ? 'green' : 'red' }} className="mt-2">
                    ₹{Number(item.PL).toFixed(2)} <br />
                    {Number(item.Percentage).toFixed(2)}%
                  </h6>
                </Col>
                <Col>
                  <h6 className='text-muted'><strong>Invested</strong></h6>
                  <h6 className="mt-2">₹{Number(item.amount).toFixed(2)}</h6>
                </Col>
                <Col>
                  <h6 className='text-muted'><strong>Current</strong></h6>
                  <h6 className="mt-2">₹{Number(item.Currentvalue).toFixed(2)}</h6>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  ) : (
    <p>No folio data available.</p>
  )}
</Container>

    </Layout>
  );
};

export default PortfolioPage;
