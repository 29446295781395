import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap'; // Importing Bootstrap for styling
import Layout from './Layout';
import '../css/HomePage.css';

const SIPTable = () => {
  const [sipData, setSipData] = useState([]);  // Initialize state for storing API data
  const [loading, setLoading] = useState(true);  // State to track loading
  const [error, setError] = useState(null);  // State to track errors
  const userId = localStorage.getItem('userID'); // Get userID from localStorage

  // useEffect to fetch SIP data from API
  useEffect(() => {
    if (!userId) {
      setError('User ID not found');
      setLoading(false);
      return;
    }

    fetch(`http://localhost/autowealth.in/api/Sipdata/sipdata.php?userId=${userId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch SIP data'); // Handle non-2xx HTTP responses
        }
        return response.json(); // Parse JSON from response
      })
      .then((data) => {
        console.log('Fetched data:', data);  // Log the fetched data to see its structure

        // Ensure the data is an array or adjust according to the response structure
        if (data.Success && Array.isArray(data.Result)) {
          setSipData(data.Result);
        } else {
          setSipData([]); // Fallback in case data is not an array
        }
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.error('Error fetching SIP data:', error);
        setError(error.message); // Set error message if there's an error
        setLoading(false); // Set loading to false in case of error
      });
  }, [userId]); // Dependency array includes userId

  // Handle loading state
  if (loading) {
    return <p>Loading...</p>;
  }

  // Handle error state
  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Layout>
      <div className="border sip-table-container-fluid">
        <h3 className="text-center m-4">Your SIPs</h3>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Scheme Name</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {sipData.length > 0 ? (
              sipData.map((sip, index) => (
                <tr key={index}>
                  <td>{sip.scheme}</td>
                  <td>{sip.achfromdate}</td>
                  <td>{sip.achtodate}</td>
                  <td>{sip.status}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center">
                  No SIP data available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </Layout>
  );
};

export default SIPTable;
