import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import Layout from './Layout';
import '../css/HomePage.css';
// import '../css/PortfolioPage.css';

const RecommendedTaxSaverFunds = () => {
  // Hardcoded tax saver fund data (can be replaced with API data in the future)
  const [taxSaverFunds, setTaxSaverFunds] = useState([
    {
      scheme: 'HDFC Long Term Advantage Fund - Regular P lan - Growth',
      rationale: 'Large-cap Bias',
    },
    {
      scheme: 'Kotak ELSS Tax Saver Fund - Growth (Regular Plan)',
      rationale: 'Multi-cap opportunity',
    },
    {
      scheme: 'Mirae Asset ELSS Tax Saver Fund (formerly Mirae Asset Tax Saver Fund ) - Regular Plan - GROWTH',
      rationale: 'Value pick',
    },
  ]);

  return (
    <Layout>
      <div className="border tax-saver-funds-table-container">
        <h3 className="text-center m-4">Recommended Tax Saver Funds</h3>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Scheme (ELSS) Funds</th>
              <th>Rationale</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {taxSaverFunds.map((fund, index) => (
              <tr key={index}>
                <td>{fund.scheme}</td>
                <td>{fund.rationale}</td>
                <td>
                  <Button variant="primary" className="me-2">Buy</Button>
                  <Button variant="success">SIP</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Layout>
  );
};

export default RecommendedTaxSaverFunds;
