


import React from 'react';
import { Carousel, Button } from 'react-bootstrap';
import Layout from './Layout';
import '../css/HomePage.css';

const HomePage = () => {
  return (
    <Layout>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={`${process.env.PUBLIC_URL}/images/slider11.png`}
            alt="First slide"
          />
          <Carousel.Caption>
            {/* <h3>First Slide Title</h3>
            <p>Description for the first slide.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={`${process.env.PUBLIC_URL}/images/slider12.png`}
            alt="Second slide"
          />
          <Carousel.Caption>
            {/* <h3>Second Slide Title</h3>
            <p>Description for the second slide.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={`${process.env.PUBLIC_URL}/images/slider13.jpg`}
            alt="Third slide"
          />
          <Carousel.Caption>
            {/* <h3>Third Slide Title</h3>
            <p>Description for the third slide.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      {/* After Carousel Section */}
      <div className="after-carousel-section text-center mt-4">
      <h3 className="section-title">Secure Your Future with Smart Investments</h3>
  <p className="section-description">
    Take charge of your financial future by investing in top-rated, 0% commission direct Mutual Funds and NPS. Join thousands of investors achieving their long-term goals with India's leading stockbroker.
  </p>
  
  <div className="features mt-5">
    <div className="row">
      <div className="col-md-4 feature-box">
        <h5>Start Small, Grow Big</h5>
        <p>Begin your investment journey with as little as ₹500. Watch your wealth grow over time with consistent, high-quality investments.</p>
      </div>
      <div className="col-md-4 feature-box">
        <h5>Expert Recommendations</h5>
        <p>Receive personalized investment advice from industry experts to align your portfolio with your financial goals.</p>
      </div>
      <div className="col-md-4 feature-box">
        <h5>Comprehensive Insights</h5>
        <p>Track and analyze your investments in real-time with our advanced reporting tools and dashboards.</p>
      </div>
    </div>
  </div>

  <div className="benefits mt-5">
    <h4>Why Invest with Us?</h4>
    <ul className="benefits-list">
      <li>Zero commission on direct Mutual Funds and NPS</li>
      <li>Top-rated funds to maximize your returns</li>
      <li>100% transparent and secure investment platform</li>
      <li>Access to SIPs, Tax Saver Funds, and more</li>
    </ul>
  </div>
  <div> <Button className="custom-aqua-button m-3" href="/invest-now">Start Investing Today</Button>
  </div>
 
        <Button id="btn" href="/login" className="custom-aqua-button">
  Login
</Button>

<p className="mt-3">
  Don't have an account? <a href="/signup" className="custom-login-link">Signup now</a>
</p>

      </div>
    </Layout>
  );
};

export default HomePage;

