import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Layout from './Layout';
import '../css/HomePage.css';

const LoginPage = () => {
  const [mobileOrEmail, setMobileOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  // Automatically remove alert after 3 seconds
  useEffect(() => {
    if (error || success) {
      const timer = setTimeout(() => {
        setError('');
        setSuccess('');
      }, 3000);
      return () => clearTimeout(timer); // Clean up timer on unmount
    }
  }, [error, success]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!mobileOrEmail || !password) {
      setError('Please enter both mobile/email and password.');
      return;
    }

    try {
      // Make an API call to your backend login API
      const api_Path = process.env.REACT_APP_API_PATH;
console.log('Api path = ' + api_Path);
const response = await axios.post(`${api_Path}user_login/users.php`, {
  UserName: mobileOrEmail, // Send either mobile or email as UserName
  UserPass: password,
});

      if (response.data.Success) {
        // Handle successful login
        setSuccess('Login is successful!');
        setError('');

        const userID = response.data.Message.userID;
        const userEmail = response.data.Message.userEmail;
        console.log('response=', response);
        console.log('userEmail=', userEmail);
        console.log('userID 2=', userID);

        // Save user details to localStorage
        if (userEmail) {
          localStorage.setItem('userEmail', userEmail);
          localStorage.setItem('userID', userID);
          localStorage.setItem('password', password); // Save password only if needed
        }

        // Redirect to the portfolio page after login
        navigate('/portfolio');
      } else {
        // Handle login failure
        setError(response.data.Message);
        setSuccess('');
      }
    } catch (error) {
      // Handle error
      setError('API not found');
      setSuccess('');
    }
  };

  return (
    <Layout>
      <Container className="mt-5">
        <h3 className="text-center mb-4 ">Login</h3>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formMobileOrEmail" className="mb-3">
            <Form.Label>
              Mobile No. or Email Address
              <span style={{ color: 'red' }}>*</span> {/* Red asterisk */}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter mobile number or email address"
              value={mobileOrEmail}
              onChange={(e) => setMobileOrEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formPassword" className="mb-3">
            <Form.Label>
              Password
              <span style={{ color: 'red' }}>*</span> {/* Red asterisk */}
            </Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>
          {/* <Button variant="primary" type="submit" className="w-100">
            Login
          </Button> */}

<div className="d-flex justify-content-center">
  <Button id="btn" type="submit" className="custom-aqua-button">
    Login
  </Button>
</div>


        </Form>
        {/* <p className="mt-3 text-center">
          Not registered yet? <a href="/signup">Sign up here</a>
        </p> */}

<p className="mt-3 text-center">
  Not registered yet?{' '}
  <a href="/signup" className="custom-signup-link">Sign up here</a>
</p>

      </Container>
    </Layout>
  );
};

export default LoginPage;
